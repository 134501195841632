<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <!-- <submenu-server menu="edge"></submenu-server>  -->

      <div class="submenu-content">  
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <!-- page title -->
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="b in item.body">

            <div v-if="b.type === 'heading'">
              <div v-if="b.value === 'Inquiry Button'">
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/><br/>
              </div>
              <div v-else>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="paragraph">
              <p v-html="b.value"></p>
            </div>

            <div v-else-if="b.type === 'image'">
              <div class="flexbox">
                <!-- <img
                :src="b.value.large.src"
                :width="b.value.large.width"
                :height="b.value.large.height"> -->
                <img class="fleximg"
                :src="b.value.large.src">
              </div>
            </div>

            <!-- table -->
            <div v-else-if="b.type === 'table'" class="datatable">

              <!-- table title-->
              <div v-if="b.value.data.table_caption">
                <br/>
                <h4>{{b.value.data.table_caption}}</h4>
              </div>

              <table>
                <!-- the image columns -->
                <thead>
                  <tr>
                    <th v-for="(h, index) in b.value.table_header_column">
                      <div>
                        <div v-if="h.value.image">
                          <img
                            :src="h.value.image.medium.src"
                            :width="h.value.image.medium.width"
                            :height="h.value.image.medium.height">
                        </div>
                        <div v-if="h.value.image2">
                          <img
                            :src="h.value.image2.medium.src"
                            :width="h.value.image2.medium.width"
                            :height="h.value.image2.medium.height">
                        </div>
                        <div v-if="h.value.heading"><br/>{{h.value.heading}}</div>
                      </div>                      
                    </th>
                  </tr>
                </thead>

                <!-- data -->
                <tbody>
                  <tr v-for="(row, index) in b.value.data.data">
                    <td v-for="column in row" v-if="index === 0" class="line">
                      {{column}}
                    </td>
                    <td v-for="column in row" v-else width="30%">
                      {{column}}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
        <!-- end of page content -->
      </div> 

  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'EdgeSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Edge Solutions", link: "EdgeSolutions"})

    getAPI
    .get('/content/pages/54')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.submenu-content {
    padding: 0 30px 30px;
}
.clear {clear:both;}
.pad {margin-right:50px;}
.paragraph {margin:30px 0;}
.block {width:30%; margin: 50px 30px 10px 0; height: 300px;}
.underline {text-decoration: overline;}

.datatable {margin-bottom:50px;}
.line {border-top:1px solid #222; border-bottom: 1px solid #222}
table td {padding:15px;}
table {border-bottom:1px solid #222}

.flexbox {
  display: flex;
  justify-content: flex-start;

}
.fleximg {
  flex: 1 1 auto;
}
</style>